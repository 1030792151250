import './index.css';

function debounce(func, timeout){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

document.addEventListener('DOMContentLoaded', () => {
  let searchBox = document.getElementById('search');
  let results = document.getElementById('results');


  searchBox.addEventListener('keyup', (e) => {
    if (e.target.value != '') {
      results.innerHTML='<span class="loading">Searching...</span>';
    } else {
      results.innerHTML='';
    }
  });

  searchBox.addEventListener('keyup', debounce((e) => {
    if (e.target.value != '') {
      fetch(`/api/search/${e.target.value}`)
      .then((response) => response.json())
      .then((cops) => {
        results.innerHTML = '';
        if (cops.length) {
          cops.forEach(cop => {
              let imageSrc = cop.item.imagePath.includes("#") ? cop.item.imagePath.replace("#", "") : cop.item.imagePath;
              let searchResult = document.createElement('li');
              let cname = cop.item.name.replace(/ /g, '-').toLowerCase();
              searchResult.innerHTML = `
                <a href="/lapd/cop/${cop.item.serialNumber}/${cname}">
                  <img src="/headshots/${imageSrc}" alt="headshot of ${cop.item.name}">
                  ${cop.item.name}<br>
                  Serial # ${cop.item.serialNumber}<br>
                  ${cop.item.rank}<br>
                  ${cop.item.area}</a>
              `;
              results.append(searchResult)
          });
        } else {
          results.innerHTML = '<span class="loading">Unfortunately, we did not find a matching result.</span>';
        }
      });
    } else {
      results.innerHTML = '';
    }
  }, 500));

});